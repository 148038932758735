.selectWrapper {
  display: flex;
  align-items: center;
}

.controlWrapper {
  flex-grow: 1;
  max-width: calc(100%);
}

.tooltipWrapper {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.selectedOptionWrapper {
  max-width: calc(100%);
}
